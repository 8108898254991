import React, {useRef, useEffect, useState} from 'react'
import hive_info from "./media/hive-main-splash-graphic-PhotoRoom.png";

const Slide_0 = () => {
  const style_prop = {
    display: "block",
    position: "relative",
    transform: "translate3d(0px, 0px, 0px)",
    backgroundColor: "none",
    fonSize:'80px'
  }

  return (
    <div id="slide-0" style={{display:'flex', flexDirection:'column', marginTop:'90px', color:'#FFFFFF', backgroundColor:'var(--splash-main_bgcolor)'}}>
      <div style={{display:'flex', flexDirection:'row'}}>
       <div style={{width:'45%', maxWidth:'900px',display:'flex',alignItems:'center'}}>
       <h1 id="s-0-h" style={{display:'flex',flexDirection:'column'}}>
          <div class="parent-line" >
            <p id="dtag" style={{fontSize:'40px',margin:'0px'}}>The Decentralized</p>
          </div>
          <div class="parent-line" >
            <p id="ctag" class="line" style={{fontSize:'40px',margin:'0px'}} >3D Printing Marketplace</p>
          </div>
          <br></br>
          <div style={{paddingLeft:'30px',display:'flex',maxWidth:'900px'}}>
          <p id="smtext" style={{fontFamily:'RobotoFont'}}>HiveFab is a community-driven 3D printing marketplace, connecting 3D printer owners to people who want things made.  </p>
          </div>
        </h1>
        </div>
        <div style={{width:'55%', display:'flex',justifyContent:'end'}}>
        <img id="slide_0_graphic" src={hive_info} /> 
        </div>
       </div> 
     <div style={{display:'flex', justifyContent:'center'}}>
       <ul className="nav-desktop__side">
          <li>
            <a href="https://app.hivefab.com/" target="_blank" className="cta yellow">
            <span>Launch App</span>
             <div className="cta_bg" ></div>
             <div className="cta_base_bg"></div>
            </a>
          </li>
        </ul>  
        </div>     
    </div>
  )
}


export default Slide_0