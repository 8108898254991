import React, {useRef, useEffect, useState} from 'react'
import printer_img from "./media/3d_printer_earning_cryptocurrency-PhotoRoom.png";
import hive_logo from "./media/HiveFab logo transp bkg.png"
import whitepaper from "./Hive Fabrication Protocol Construction Paper.pdf"

export const section_content_style = { 
  display:'flex',
  flexDirection:'column',
  marginTop:'40px',
  marginBottom:'30px',
  marginLeft:'2%',
  marginRight:'2%',
  alignItems:'center',
  justifyContent:'center',
  gap:'3%'
}

const column_style = {
  width:'85%', 
  maxWidth:'600px', 
  display:'flex', 
  flexDirection:'column', 
  wordWrap:'break', 
  alignItems:'center', 
  justifyContent:'center',
  }

const link_style = {
  color:'#FFFFFF', 
  fontSize:'1.2rem',
  justifyContent:'center', 
  display:'flex',
  fontFamily:'RobotoFont',
  textDecoration: "none",
  lineHeight:'40px'
}

const FooterTab_mobile = () => {
  const style_prop = {
    display: "block",
    position: "relative",
    transform: "translate3d(0px, 0px, 0px)",
    backgroundColor: "none",
    fonSize:'80px',
  }

  return (
    <div id="footer" style={{zIndex:'10',display:'flex', flexDirection:'column', marginTop:'90px', color:'#FFFFFF'}}>
      <div id="top-portion">
        <div style={{display:'flex', flexDirection:'column'}}>
        <div style={{width:'100%', display:'flex',justifyContent:'center'}}>
          <img id="print_graphic" src={printer_img} style={{width:'70%'}} /> 
          </div>
         <div style={{width:'100%', maxWidth:'900px',display:'flex',alignItems:'center'}}>
         <h1 id="s-0-h" style={{display:'flex',flexDirection:'column'}}>
            <div class="parent-line" >
              <p id="dtag" style={{fontSize:'40px',margin:'0px'}}>Step Into the Future of 3D Printing</p>
            </div>
            <br></br>
            <div style={{paddingLeft:'30px',display:'flex',maxWidth:'900px'}}>
            <p id="smtext" style={{fontFamily:'RobotoFont', fontSize:'20px'}}>Empower your creativity in a decentralized world. Create, earn, and connect with like-minded enthusiast and pioneers.</p>
            </div>
          </h1>
          </div>
          
         </div> 
       <div style={{display:'flex', justifyContent:'center', height:'100px'}}>
         <ul className="nav-desktop__side" style={{padding:'0px'}}>
            <li style={{height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <a href="https://app.hivefab.com/" target="_blank" className="cta yellow">
              <span>Explore Jobs</span>
               <div className="cta_bg" ></div>
               <div className="cta_base_bg"></div>
              </a>
            </li>
          </ul>  
          </div> 
      </div>
      <div id="footer-portion" >
        <div style={section_content_style}>
          <div style={column_style}>
            <img id="nav_logo" src={hive_logo} />
          </div>
          <div style={column_style}>
            <h2 style={{color:'var(--hive-blue)', fontSize:'1.8rem', justifyContent:'center', display:'flex'}}>Resources:</h2>
            <a href={whitepaper} style={link_style} target="blank" download="Hive Protocol">White Paper</a>
            <a href="/terms" style={link_style} target="blank" >Terms of Use</a>
            <a href="/policy" style={link_style} target="blank" >Privacy Policy</a>
          </div>
          <div style={column_style}>
            <h2 style={{color:'var(--hive-blue)', fontSize:'1.8rem', justifyContent:'center', display:'flex'}}>Community:</h2>
            <a style={link_style} href="https://discord.com/channels/1032093195124875384/1032093195124875387" target="blank">Discord</a>
            <a style={link_style} href="https://www.instagram.com/hivefabrication/" target="blank">Instagram</a>
            <a style={link_style} href="https://twitter.com/hivefabrication" target="blank">Twitter</a>
          </div>
        </div>
      </div>    
    </div>
  )
}


export default FooterTab_mobile