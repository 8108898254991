
import React, { Component, Fragment, lazy, Suspense, useEffect , useFetch, useState} from 'react'
import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';


const TermsPage = () => {



  return (
    <React.Fragment>
       <div className="dash-main"  >
        <div className="dash-hdr">
          <h3>Hive, LLC Terms of Use </h3>
        </div>
        <div className="dash-lv1">
          <p>Terms of Use
Introduction
Welcome to HiveFab! These Terms of Use ("Terms") govern your access to and use of the website located at hivefab.com (the "Platform"), as well as the services provided by Hive, LLC ("we," "us," or "our") through the Platform, including but not limited to the hosting and facilitation of the HiveFab protocol, a protocol to build blockchain-based marketplaces that connect individuals who own a fabrication tool (e.g. a 3D printer) with those who require fabrication (e.g. 3D printing services) (collectively, the "Services"). By accessing or using the Platform and the Services, you agree to be bound by these Terms and our Privacy Policy, which is incorporated herein by reference. If you do not agree to these Terms or the Privacy Policy, you must not access or use the Platform or the Services.
Our Role
Hive, LLC provides a platform utilizing the HiveFab protocol for connecting people who own 3D printers with those who want to have something 3D printed. Please note that Hive, LLC only provides the technical infrastructure and tools for hosting and facilitating the HiveFab protocol, and we are not responsible for any content uploaded or fabricated on the Platform. We also do not create, review, or endorse any content uploaded or fabricated on this platform. We are not responsible for any disputes, claims, losses, or damages arising from your use of the Services or your interactions with other users. The Platform is intended to serve as a decentralized marketplace where users can interact directly with each other in a peer-to-peer manner, and Hive, LLC does not act as an intermediary or assume any liability for the transactions or agreements between users.
Account and Registration
To access certain features available through the Platform or the Services (e.g., being able to quote a job), you must register for an account. When you register for an account, you may be required to provide us or a third party with some information about yourself, such as your email address or other contact or identifying information. You agree that the information you provide to us is accurate and that you will keep it accurate and up to date at all times. When you register, you will be asked to provide a password. You are solely responsible for maintaining the confidentiality of your account and password, and you accept responsibility for all activities that occur under your account. If you have reason to believe that your account is no longer secure, then you must immediately notify us at contact@hivefab.com.
3.4 Connecting Digital Wallet
When using the Platform and/or the Services, you may be required to connect to your digital wallet through one of the compatible third-party software wallets, such as MetaMask, Coinbase, WalletConnect, or similar software. Software wallets constitute third-party services, and Hive, LLC is not responsible for, does not endorse, shall not be held liable in connection with, and does not make any warranties, whether express or implied, as to the software digital wallets used by you with the Platform or the Services. When using third-party software wallets, review applicable terms and policies that govern your use of such software.
Hive, LLC never receives access or control over your digital wallet. Therefore, you are solely responsible for securing your digital wallet and credentials thereto. You may disconnect your digital wallet from the Platform and the Services at any time directly through the Platform.
3.5 Ethereum and Polygon Gas Charges
Some Services involve the use of the Ethereum or Polygon Blockchains, which may require that you pay a fee, commonly known as “Gas Charges,” (base fee is burned and tip is sent to the miner or validator of the proof-of-stake) for the computational resources required to perform a transaction on the Ethereum or Polygon Blockchain. You acknowledge and agree that Hive, LLC has no control over: (a) any Ethereum or Polygon Blockchain transactions; (b) the method of payment of any Gas Charges; or (c) any actual payments of Gas Charges. Accordingly, you must ensure that you have a sufficient balance of Ether or Matic stored at your Ethereum or Polygon Address to complete any transaction on the Ethereum or Polygon Blockchain before initiating such Ethereum or Polygon Blockchain transaction. We will make reasonable efforts to notify you of any Ethereum or Polygon Gas Charges before initiating any Services that require the use of the Ethereum or Polygon Blockchain. You acknowledge that Ethereum or Polygon Gas Charges can change quickly, and Hive, LLC is not responsible for any such changes.
3.6 Conditions and Restrictions
We may, at any time and in our sole discretion, restrict access to, or otherwise impose conditions or restrictions upon the use of, the Services or the Platform, with or without prior notice.
User Content
You are solely responsible for any content that you upload, submit, or otherwise transmit through the Services, including but not limited to 3D models, designs, images, or other materials ("User Content"). By submitting User Content, you represent and warrant that you have all necessary rights and permissions to use, share, and sublicense the User Content in connection with the Services.
License to Use User Content
By submitting User Content, you acknowledge and agree that the 3D model(s) will be visible publicly for the duration that the job is available, although only the selected Fabricator will be able to download the 3D model(s) after the job is initialized. However, no one, including Hive, LLC, will have licensing or ownership rights to the 3D model(s) associated with those job(s). The only person who will have temporary licensing and the ability to download the 3D model will be the person who was chosen to complete the job.

Restrictions on Use of 3D Models by Fabricators

Upon the payment being sent to the smart contract, the chosen individual for a 3D print job (the "Fabricator") will be granted a temporary license to access, download, and use the 3D model for the sole purpose of fulfilling the print job. This temporary license will automatically and permanently expire either after the job is successfully completed and confirmed by the job poster, or 30 days after the estimated job completion date, whichever comes first.
During the period of this temporary license, the Job Completer is strictly prohibited from:
Modifying the 3D model for any purpose other than completing the print job.
Sharing the 3D model with any third party or making it publicly accessible.
Replicating or creating additional prints of the 3D model beyond the agreed-upon scope of the print job.
Retaining any copies of the 3D model after the expiration of the temporary license.
The Job Completer agrees to delete any and all copies of the 3D model in their possession promptly after the expiration of the temporary license. Failure to comply with these restrictions may result in penalties, account suspension, or legal action, as deemed appropriate by Hive LLC.

Prohibited Content
You agree not to upload, submit, or otherwise transmit any User Content that: (a) infringes or violates any intellectual property, privacy, or other rights of any third party; (b) is illegal, obscene, defamatory, threatening, harassing, or otherwise objectionable; (c) encourages or promotes illegal activity; or (d) contains software viruses or any other harmful computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.
Termination
Hive, LLC may, in its sole discretion, terminate your access to the Services or your account at any time, with or without notice, for any reason, including but not limited to violation of these Terms. Upon termination of your access to the Services, your right to use the Services will immediately cease.
Disclaimers
The Services are provided on an "as-is" and "as-available" basis, and Hive, LLC disclaims any and all warranties, whether express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. Hive, LLC does not warrant that the Services will be uninterrupted, timely, secure, or error-free.
Limitation of Liability
In no event shall Hive, LLC be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or other intangible losses, resulting from your access to or use of the Services, even if Hive, LLC has been advised of the possibility of such damages.
Indemnification
You agree to indemnify and hold harmless Hive, LLC and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or in connection with your access to or use of the Services or your violation of these Terms.
Changes to the Terms
Hive, LLC may revise and update these Terms from time to time in its sole discretion. All changes are effective immediately when posted, and apply to all access to and use of the Services thereafter. Your continued use of the Services following the posting of revised Terms means that you accept and agree to the changes.
Governing Law and Jurisdiction
These Terms are governed by and construed in accordance with the laws of Alabama, without giving effect to any principles of conflicts of laws. You agree to submit to the exclusive jurisdiction of the courts located in Madison County, Alabama for any disputes arising out of or relating to these Terms or your use of the Services.
GDPR and CCPA Provisions
In accordance with the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA), Hive, LLC is committed to protecting your personal information and respecting your privacy rights. Our Privacy Policy outlines how we collect, use, store, and disclose your personal data. By using the Services, you acknowledge and agree to the terms of our Privacy Policy.
If you are a resident of the European Union or the United Kingdom, you have certain rights under the GDPR, including the right to access, rectify, or erase your personal data, restrict or object to its processing, or request its portability. If you are a California resident, you have specific rights under the CCPA, including the right to know what personal information is collected, the right to delete personal information held by businesses, and the right to opt-out of the sale of personal information.
To exercise your rights under the GDPR or CCPA, please contact us at contact@hivefab.com.
Miscellaneous
If any provision of these Terms is found to be invalid or unenforceable, that provision shall be enforced to the maximum extent possible and the remaining provisions shall remain in full force and effect. No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision. Hive, LLC's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
Contact Information
If you have any questions or concerns regarding these Terms or the Services, please contact us at contact@hivefab.com .
</p>
        </div>


      </div>

       
    </React.Fragment>
  );
}


export default (TermsPage);
