import React, {useRef, useEffect, useState} from 'react'
import hive_info from "./media/hive-main-splash-graphic-PhotoRoom.png";

const style_prop = {
  display: "block",
  position: "relative",
  transform: "translate3d(0px, 0px, 0px)",
  backgroundColor: "none",
  fonSize:'80px'
}

const image_div = {
  width:'100%', 
  display:'flex',
  justifyContent:'end',
  alignItems:'center',
  justifyContent:'center',
  position: 'relative',
  textAlign: 'center',
}

const image_style = {
  position:'fixed',
  display: 'block',
  margin: 'auto',
  top:0,
  opacity:'0.7',
  width:'90vh',
  height:'90vh'
}

const text_div = {width:'95%', 
  maxWidth:'900px',
  display:'flex',
  alignItems:'center',
  display:'flex',
  flexDirection:'column',
  position:'absolute',
  top:'40%',
  left:'50%',
  transform:'translate(-50%,-50%)',
  textAlign:'center',
  opacity:'1'
}

const back_div = { 
  backgroundColor: '#000000',
  opacity:'1',
  borderRadius:'20px',
  backgroundImage: 'radial-gradient(#090, #fff, #2a4f32)',
}

const slide_style = {display:'flex', 
  flexDirection:'column', 
  marginTop:'80px', 
  color:'#FFFFFF', 
  backgroundColor:'var(--splash-main_bgcolor)',
  height:'80vh'
}


const Slide_0_mobile = () => {
  return (
    <div id="slide-0" style={slide_style}>
      <div style={{display:'flex', flexDirection:'column', height:'100%'}}>
        <div style={image_div}>
          <div style={back_div}>
          <img id="slide_0_graphic" src={hive_info} style={image_style} /> 
          </div>
        </div>
        
         <div style={text_div}>
           <h1 id="s-0-h" style={{display:'flex',flexDirection:'column'}}>
              <div class="parent-line" >
                <p id="dtag" style={{fontSize:'40px',margin:'0px'}}>The Decentralized</p>
              </div>
              <div class="parent-line" >
                <p id="ctag" class="line" style={{fontSize:'40px',margin:'0px'}} >3D Printing Marketplace</p>
              </div>
              <br></br>
              <div style={{display:'flex',maxWidth:'900px'}}>
              <p id="smtext" style={{fontFamily:'RobotoFont', fontSize:'22px',lineHeight:'34px'}}>HiveFab is a community-driven 3D printing marketplace, connecting 3D printer owners to people who want things made.  </p>
              </div>
              <div id="background-div" style={back_div}></div>
            </h1>
          </div>
        
       </div> 
     <div style={{display:'flex', justifyContent:'center',height:'150px'}}>
       <ul className="nav-desktop__side" style={{padding:'0px'}}>
          <li style={{height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <a href="https://app.hivefab.com/" target="_blank" className="cta yellow">
            <span>Launch App</span>
             <div className="cta_bg" ></div>
             <div className="cta_base_bg"></div>
            </a>
          </li>
        </ul>  
        </div>     
    </div>
  )
}


export default Slide_0_mobile