import React, { Component, Fragment, lazy, Suspense, useEffect , useFetch, useState} from 'react'
import { useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';


const PolicyPage = () => {



  return (
    <React.Fragment>
       <div className="dash-main"  >
        <div className="dash-hdr">
          <h3>Hive, LLC Privacy Policy </h3>
        </div>
        <div className="dash-lv1">
          <p>Hive, LLC Privacy Policy
Last Updated: April 16, 2023
Hive, LLC ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, store, and disclose your personal information when you use our website located at [specific URL] (the "Platform") and the services provided by us through the Platform (collectively, the "Services"). By accessing or using the Platform and the Services, you agree to the terms of this Privacy Policy.
Information We Collect
We collect information about you in various ways when you use our Platform and Services. The information we collect may include:
a. Personal Information: We collect personal information you provide to us, such as your name, email address, mailing address, phone number, or other information you submit when creating an account, submitting a 3D Print Job, or interacting with the Services.
b. Crypto Wallet Information: When you connect your digital wallet to use the Services, we may collect information related to your crypto wallet, such as your wallet address and transaction history.
c. Usage Information: We collect information about your usage of the Platform and the Services, such as the 3D models you upload, the 3D Print Jobs you submit or accept, and other interactions with the Platform.
d. Device and Browser Information: We collect information about your device and browser, such as your IP address, operating system, browser type, and other information that your browser or device sends automatically.
e. Cookies and Tracking Technologies: We use cookies and other tracking technologies to collect information about your activity on the Platform, including pages you visit, links you click, and other actions you take. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our website or service.
How We Use Your Information
We use the information we collect for various purposes, including to:
a. Provide, maintain, and improve the Services.
b. Communicate with you about the Services, respond to your inquiries, and provide customer support.
c. Monitor and analyze trends, usage, and activities in connection with the Services.
d. Personalize and improve your experience on the Platform.
e. Enforce our Terms of Use and other policies.
f. Comply with legal requirements and cooperate with law enforcement agencies.
How We Share Your Information
We may share your information in the following circumstances:
a. With third-party service providers that help us provide the Services, such as hosting providers, payment processors, and analytics providers.
b. When required by law, such as in response to a subpoena, court order, or other legal process.
c. In connection with a merger, acquisition, or sale of all or a portion of our assets.
d. With your consent or at your direction.
Security
We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee the absolute security of your information.
Third-Party Links and Services
Our Platform and Services may contain links to third-party websites or services. We are not responsible for the privacy practices of these third-party websites or services, and we encourage you to read their privacy policies before providing any personal information.
Children's Privacy
Our Services are not intended for or directed at children under the age of 13. We do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will take steps to delete that information as soon as possible.
GDPR and CCPA Provisions
a. GDPR: If you are a resident of the European Union, you have certain rights under the General Data Protection Regulation (GDPR). These rights include:
The right to access, update, or delete the personal information we have about you.
The right to rectification if your personal information is inaccurate or incomplete.
The right to object to our processing of your personal information.
The right to restrict our processing of your personal information under certain circumstances.
The right to data portability, allowing you to obtain and reuse your personal information for your own purposes across different services.
The right to withdraw consent if you have previously given it for the processing of your personal information.
To exercise any of these rights, please contact us at contact@hivefab.com. We will respond to your request within a reasonable time frame and in accordance with applicable laws.
b. CCPA: If you are a resident of California, you have certain rights under the California Consumer Privacy Act (CCPA). These rights include:
The right to know what personal information we collect, use, disclose, and sell about you.
The right to request the deletion of your personal information, subject to certain exceptions.
The right to opt-out of the sale of your personal information.
The right to non-discrimination for exercising any of your CCPA rights.
To exercise any of these rights, please contact us at contact@hivefab.com. We will respond to your request within a reasonable time frame and in accordance with applicable laws.
Changes to This Privacy Policy
We may update this Privacy Policy from time to time to reflect changes in our practices, the Services, or legal requirements. When we make changes, we will revise the "Last Updated" date at the beginning of this policy. Your continued use of the Platform and Services after any changes to this Privacy Policy constitutes your acceptance of the updated policy.
Contact Us
If you have any questions about this Privacy Policy or our privacy practices, please contact us at: contact@hivefab.com
</p>
        </div>


      </div>

       
    </React.Fragment>
  );
}


export default (PolicyPage);
