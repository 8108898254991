import React from 'react'
import './Nav.css';
import hive_logo from "./media/HiveFab logo transp bkg.png"

const Navbar_mobile = () => {
  return (
    <nav className="nav-desktop">
        <div className="nav-desktop__container" style={{dispplay:'flex', flexDirection:'row',justifyContent:'space-evenly'}}>
            <a className="nav-desktop__logo" >
                <img id="nav_logo" src={hive_logo} />
            </a>
        </div>
    </nav>
  )
}


export default Navbar_mobile

