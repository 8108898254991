import React, { useEffect, useState } from 'react'
import './Content.css'
import './root.css'
import Slide_0 from "./Slide_0"
import Slide_0_mobile from "./Slide_0_mobile"
import Section_1 from "./Section_1"
import Section_1_mobile from "./Section_1_mobile"
import Navbar from "./Navbar"
import Navbar_mobile from "./Navbar_mobile"
import ReviewTab from "./ReviewTab"
import ReviewTab_mobile from "./ReviewTab_mobile"
import FooterTab from "./FooterTab"
import FooterTab_mobile from "./FooterTab_mobile"
const Content = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkScreenSize = () => {
        if (window.innerWidth < 900 || window.outerWidth < 900) {
          setIsMobile(true)
          console.log('screen is mobile device')
        } else {
          setIsMobile(false)
        }
    };
    // Call the checkScreenSize function when the component mounts
    checkScreenSize();
    // Add an event listener to update isMobile on window resize
    window.addEventListener('resize', checkScreenSize);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div  id="main-content-div" style={{display:'flex', flexDirection:'column',width:'100%', alignContent:'center', alignItems:'center'}}>
      {isMobile ? <Navbar_mobile/> : <Navbar/>}
      {isMobile ? <Slide_0_mobile/> : <Slide_0/>}
      {isMobile ? <Section_1_mobile/> : <Section_1/>}
      {isMobile ? <ReviewTab_mobile/> : <ReviewTab/>}
      {isMobile ? <FooterTab_mobile/> : <FooterTab/>}
    </div>
  )
}


export default Content