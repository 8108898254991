import React, { Component, useEffect, useRef, useState } from 'react';

export const name_text_style ={
  fontWeight:'bold',
  textAlign: 'center'
}

export const header_text_style = {
  display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  color:'var(--team-primary)'
}

export const head_text_style = {
  display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  color:'var(--team-secondary)'
}

export const section_header_style = {
  display:'flex',
  justifyContent:'end',
  fontWeight:'bold',
  color:'var(--team-secondary)',
  marginRight:'15px'
}

const profile_image_style = {
  width:'40%', 
  flexDirection:'column',
  position:'absolute',
  left:'0px',
  top:'50px', 
  justifyContent:'center'
}

export const section_comp_style = {
  marginTop:'4%',  
  minHeight:'400px', 
  marginLeft:'2%',
  marginRight:'2%',
  display:'flex',
  flexDirection:'column',
  alignItems:'center'
}

export const section_content_style = { 
  display:'flex',
  backgroundColor:'var(--splash-main_bgcolor)', 
  marginTop:'40px',
  marginBottom:'30px',
  marginLeft:'2%',
  marginRight:'2%',
  alignItems:'center',
  justifyContent:'center',
  gap:'3%'
}

const review_1 = "Money is getting tighter, and 3D Printing things for people on HiveFab has helped me make a bit extra on the side."
const review_1_name = "-David R."

const review_2 = "I needed a basic prototype made, and I was worried I'b be over-charged. The multipel bid I got on HiveFab allowed me to feel more confident I was getting the right price."
const review_2_name = "-Suzie Q."

const review_3 = "As a 3D printer owner, HiveFab has helped me earn extra income by connecting me with clients in need of printing services. The platform is user-friendly, and the smart contract system with NFTs makes transactions secure and hassle-free. "
const review_4_name = "-Joe B."

const svgStyle = {
  fill: 'var(--hive-yellow)',
};


const ReviewTab = () => {
  const [showMore, setShowMore] = useState(true)
  const MoreIcon = () =>{
    return(
      <div style={{width:'60px', marginLeft:'30px'}}>
      <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50"><path style={svgStyle} className="hive-icon" d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z"/></svg>
      </div>
      )
  }

  const LessIcon = () =>{
    return(
      <div style={{width:'60px',marginLeft:'30px'}}>
      <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 -960 960 960" width="50"><path style={svgStyle} className="hive-icon" d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z"/></svg>
      </div>
      )
  }

  const showDiv = () => {
    if (showMore === true){
      setShowMore(false)
    } else {
      setShowMore(true)
    }
  }
  
  return (
    <div id="media-comp" className='section-div' style={section_comp_style}>
      <h1 style={{color:'var(--hive-yellow)',display:'flex',justifyContent:'center'}} onClick={showDiv}>What some people are saying: {showMore ? null : null} </h1>
      {showMore ? <div style={section_content_style}>
        <div style={{width:'28%', maxWidth:'600px', display:'flex', flexDirection:'column', wordWrap:'break', alignItems:'center', justifyContent:'center'}}>
          <p style={{color:'#FFFFFF', fontSize:'1.5rem',justifyContent:'center', display:'flex'}}> {review_1} </p>
          <h2 style={{color:'#FFFFFF', fontSize:'1.8rem', justifyContent:'center', display:'flex'}}>{review_1_name}</h2>
        </div>

      </div> : null}
    </div>
  )
}


export default ReviewTab
