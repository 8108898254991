import React, { Component, useEffect, useRef, useState } from 'react';
import img_1 from "./media/3d_printer_icon_2-PhotoRoom.png";
import img_2 from "./media/blockchain_icon_2-PhotoRoom.png";
import img_3 from "./media/white_cube_with_XYZ-PhotoRoom.png";


export const name_text_style ={
  fontWeight:'bold',
  textAlign: 'center'
}

export const header_text_style = {
  display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  color:'var(--team-primary)'
}

export const head_text_style = {
  display:'flex',
  justifyContent:'center',
  fontWeight:'bold',
  color:'var(--team-secondary)'
}

export const section_header_style = {
  display:'flex',
  justifyContent:'end',
  fontWeight:'bold',
  color:'var(--team-secondary)',
  marginRight:'15px'
}

const profile_image_style = {
  width:'40%', 
  flexDirection:'column',
  position:'absolute',
  left:'0px',
  top:'50px', 
  justifyContent:'center'
}

export const section_comp_style = {
  marginTop:'4%',  
  minHeight:'400px', 
  border:'1px solid var(--team-secondary)',
  marginLeft:'2%',
  marginRight:'2%',
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  zIndex:'10',
}

export const section_content_style = { 
  display:'flex',
  flexDirection:'column',
  width:'95%',
  marginTop:'40px',
  marginBottom:'30px',
  marginLeft:'2%',
  marginRight:'2%',
  alignItems:'center',
  justifyContent:'center',
  textAlign:'center',
  gap:'3%'
}


const Section_1_mobile = () => {
  return (
    <div id="media-comp" className='section-div' style={section_comp_style}>
      <h1 style={{color:'#FFFFFF',display:'flex',justifyContent:'center'}}>Powered by Web 3.0 </h1>
      <div style={section_content_style}>
        <div style={{width:'80%', maxWidth:'600px', display:'flex', flexDirection:'column', wordWrap:'break', alignItems:'center', justifyContent:'center'}}>
          <img id="i-1" src={img_1} style={{width:'60%'}}/>
          <h2 style={{color:'var(--hive-yellow)', fontSize:'1.8rem', justifyContent:'center', display:'flex'}}>Monetize Your 3D Printer:</h2>
          <p style={{color:'#FFFFFF', fontSize:'1.5rem',justifyContent:'center', display:'flex'}}> Turn your idle 3D printer into a source of income by offering your services to a global community of makers and businesses.</p>
        </div>
        <div style={{width:'80%', maxWidth:'600px', display:'flex', flexDirection:'column', wordWrap:'break', alignItems:'center', justifyContent:'center'}}>
        <img id="i-2" src={img_2} style={{width:'60%'}} />
        <h2 style={{color:'var(--hive-yellow)', fontSize:'1.8rem', justifyContent:'center', display:'flex'}}>Trust and Security:</h2>
          <p style={{color:'#FFFFFF', fontSize:'1.5rem',justifyContent:'center', display:'flex'}}> Our platform leverages blockchain technology, smart contracts, and NFT's to ensure a secure, transparent transaction process, with funds held in escrow until your 3D printed part is devlivered to your satisfaction. </p>
        </div>
        <div style={{width:'80%', maxWidth:'600px', display:'flex', flexDirection:'column', wordWrap:'break', alignItems:'center', justifyContent:'center'}}>
        <img id="i-3" src={img_3} style={{width:'60%'}}/>
        <h2 style={{color:'var(--hive-yellow)', fontSize:'1.8rem', justifyContent:'center', display:'flex'}}>Upload 3D Models and Recieve Competitive Bids:</h2>
          <p style={{color:'#FFFFFF', fontSize:'1.5rem',justifyContent:'center', display:'flex'}}>Easily upload your 3D model, receive competitive quotes from 3D printer owners, and choose the best option based on price and delivery time, all through our intuitive Web-3 interface. </p>
        </div>
      </div>
    </div>
  )
}


export default Section_1_mobile
