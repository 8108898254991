import React, { Component } from 'react'
import {Link, BrowserRouter as Router, Route, Routes, Outlet, useLocation} from 'react-router-dom'
import './App.css'
import Navbar from "./Navbar"
import Content from "./Content"
import PolicyPage from './PolicyPage'
import TermsPage from './TermsPage'

class App extends Component {

  render() {
    return (
      <div style={{display:'flex',flexDirection:'column', backgroundColor:'#000000'}}>
          <Router>
            <Routes>
              <Route path="/" element={<Content />}/>
              <Route path="terms" element={<TermsPage />}/>
              <Route path="policy" element={<PolicyPage />}/>
            </Routes>
          </Router>
      </div>   
    );
  }
}



export default App

