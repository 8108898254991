import React from 'react'
import './Nav.css';
import hive_logo from "./media/HiveFab logo transp bkg.png"

const Navbar = () => {
  return (
    <nav className="nav-desktop">
        <div className="nav-desktop__container" style={{dispplay:'flex', flexDirection:'row',justifyContent:'space-evenly'}}>
            <a className="nav-desktop__logo" >
                <img id="nav_logo" src={hive_logo} />
            </a>
            <div id='tabs' style={{display:'flex',justifyContent:'space-between'}}>
              
            </div>
            <ul className="nav-desktop__side">
              <li>
                <a href="https://app.hivefab.com/" target="_blank" className="cta yellow">
                <span>Launch App</span>
                 <div className="cta_bg" ></div>
                 <div className="cta_base_bg"></div>
                </a>
              </li>
            </ul>
        </div>
    </nav>
  )
}


export default Navbar

